export const BUSINESS_DETAILS = {
    businessFullName: 'Livera Designers and Developers Pvt Ltd ',
    businessShortName: 'Livera',
    mail: 'hello@livera.design',
    phoneNumber: '+91 8722 959595',
    openingHours: '09:00 to 20:00',
    address: {
        sector: 'Livera Designers and Developers Private Limited',
        siteName: 'Prolance Interior Design hub',
        placeName: 'Jubilee Hills',
        cityName: 'Hyderabad',
        state: 'Telangana',
        pinCode: 500033,
        url:"https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3806.361780028345!2d78.375513!3d17.44239!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTfCsDI2JzMyLjYiTiA3OMKwMjInMzEuOSJF!5e0!3m2!1sen!2suk!4v1694505979730!5m2!1sen!2suk",
        fullAddress: 'Livera Designers and Developers Private Limited, Prolance Interior Design hub, Jubilee Hills, Hyderabad, Telangana 500033.',
        location: 'Livera Designers and Developers Private Limited, Prolance Interior Design hub,3rd Floor, Apurupa Towers, Plot No 467, Jawahar Colony, Opp. to Krishnapatnam Restaurant Rd Number 36, Jubilee Hills, Hyderabad, Telangana 500033',
    },
    owner: {
        name: 'Srinivas Prathipati',
        experience: 15,
        projects: 125
    }
}

export const SOCIAL_MEDIA_LINKS = [
    {
        icon: 'instagram',
        href: 'https://instagram.com/livera.design?igshid=MzMyNGUyNmU2YQ=='
    },
    {
        icon: 'facebook',
        href: 'https://www.facebook.com/profile.php?id=100095473537055&mibextid=ZbWKwL'
    },
    {
        icon: 'whatsapp',
        href: 'https://wa.me/message/BPMRB6RAVULBH1'
    },
];
