import main from '../../assets/images/services/electrical/main.jpg';
import illuminate from '../../assets/images/services/electrical/illuminate.jpg';
import elegant from '../../assets/images/services/electrical/elegant.jpg';
import { META_DESCRIPTION, META_TITLE } from '../../constants/seo.constants';
import Layout1 from '../ServicesLayout/Layout1';

const info = {
  metaTitle: META_TITLE.ELECTRIC_LAYOUTS,
  metaDescription: META_DESCRIPTION.ELECTIRC_LAYOUTS,
  title: "Electric Layouts",
  mainHeading: "Lighting Up Your World: Innovative Electrical Layouts by Livera",
  image1: main,
  mainParagraphs: ["Step into the world of Livera's Electrical Layouts, where we redefine the way you experience interior spaces. As a leading interior design and development company, we recognize the vital role that well-planned electrical layouts play in creating functional and visually appealing environments", "Our team of dedicated experts excels in crafting layouts that seamlessly integrate lighting, power, and technology solutions, ensuring not only practicality but also elegance in every corner of your home or workspace. Explore Livera's Electrical Layouts and embark on a journey where innovation and aesthetics converge to illuminate your world."],
  points: [
    {
      className: "pt-10",
      image: illuminate,
      title: "Creating Illuminating Environments",
      description: "Discover how Livera illuminates your spaces with innovative electrical layouts",
      bulletPoints: [
        "Comprehensive Consultation",
        "Innovative Design",
        "Efficiency and Sustainability",
        "Tailored Solutions",
        "Smart Integration",
        "Safety and Compliance"
      ]
    },
    {
      className: "pt-25",
      image: elegant,
      title: "Executing Brilliance",
      description: "Explore Livera's meticulous approach to bringing electrical layouts to life",
      bulletPoints: [
        "Skilled Implementation",
        "Seamless Technology Integration",
        "Quality Craftsmanship",
        "On-Time Project Completion",
        "Continuous Support"
      ]
    }
  ],
  name: "electric-layout"
}

const ElectricLayout = () => {
  return (
    <Layout1 info={info} />
  )
}

export default ElectricLayout