export const META_TITLE = {
    HOME: 'Livera | Redefining Interior Design for Modern Living',
    CONTACT_US: 'Contact Livera - Premier Interior Designers for Your Dream Home',
    ARCHITECTURE_SERVICE: 'Architecture Services by Livera - Building Dreams into Reality',
    INTERIOR_DESIGN_SERVICE: 'Interior Design by Livera - Create Inspiring Interiors',
    RETAIL_DESIGNS_SERVICE: 'Commercial Design Solutions by Livera - Elevate Your Brand',
    LAYOUTS_SERVICE: '2D/3D Layout Services by Livera - Visualize Your Space',
    INTER_DESIGN_SERVICE: "Inter Design Services by Livera - Creating Unique Interiors",
    DECORATION_ART_SERVICE: 'Decoration Art Services by Livera - Elegance in Every Detail',
    PROJECTS: "Livera's Design and Architecture Portfolio",
    HOME_THEATER: "Luxury Home Theater Design Services | Livera Interior Design",
    HOME_AUTOMATION: "Livera | Elevating Homes with Intelligent Home Automation Solutions",
    FURNITURE_LAYOUTS: "Livera | Expert Furniture Layout Design for Stunning Interiors",
    ELECTRIC_LAYOUTS: "Livera | Cutting-Edge Electrical Layouts for Contemporary Interiors",
    PROJECT_DEVELOPMENT: "Elevate Your Living Spaces with Livera: Interior Design Excellence",
    404: "Oops! Page Not Found - Livera Interior Design"
}

export const META_DESCRIPTION = {
    HOME: 'Welcome to Livera, your destination for exceptional interior design. Discover our creative solutions and transform your living spaces with style.',
    CONTACT_US: "Contact Livera for personalized design and architecture solutions. Let's collaborate to bring your vision to life, whether it's for interior design, retail spaces, or architectural projects.",
    ARCHITECTURE_SERVICE: "Explore Livera's comprehensive architecture services. Our experienced team specializes in creating innovative architectural designs that bring your dreams to life.",
    INTERIOR_DESIGN_SERVICE: "Livera's interior design services combine creativity and functionality. Transform your living or working spaces with our expert interior design solutions.",
    RETAIL_DESIGNS_SERVICE: "Discover Livera's retail design expertise. We transform spaces into captivating retail environments that enhance your brand's presence and customer experience.",
    LAYOUTS_SERVICE: "Livera's 2D/3D layout services help you visualize your projects with precision. Explore our detailed layouts for better planning and execution.",
    INTER_DESIGN_SERVICE: "Livera's Inter Design services are all about crafting unique and captivating interiors. Explore how we infuse creativity into every space we design.",
    DECORATION_ART_SERVICE: 'Discover the art of decoration with Livera. Our decoration art services add elegance and charm to your spaces with meticulous attention to detail.',
    PROJECTS: "Explore Livera's diverse portfolio of design and architecture projects, including interior design, retail designs, 2D/3D layouts, and decoration art. Get inspired and visualize the possibilities for your space or project",
    HOME_THEATER: "Elevate your entertainment experience with Livera's top-rated home theater design services. Discover how our expert interior designers transform your space into a cinematic haven.",
    HOME_AUTOMATION: "Livera, a leading interior design and development company, offers cutting-edge Home Automation Services. Seamlessly integrate technology into your living spaces for a modern, efficient, and luxurious lifestyle. Explore our innovative solutions today",
    FURNITURE_LAYOUTS: "Elevate your interior spaces with Livera's expert furniture layout design services. Discover how our experienced team crafts functional and visually striking layouts that redefine the way you experience your living spaces. Explore our Furniture Layouts today",
    ELECTIRC_LAYOUTS: "Enhance your indoor spaces with Livera's skilled electrical layout solutions. Explore how we effortlessly incorporate lighting, power, and technology to craft environments that are both functional and aesthetically pleasing. Dive into the world of Livera's Electrical Layouts today!",
    PROJECT_DEVELOPMENT: "Discover Livera's interior design and development expertise! Explore our comprehensive project development process and see how we turn your vision into reality. From conceptualization to the final reveal, we're your trusted partner in creating stunning, functional, and personalized interiors. Contact us today to begin your journey towards a beautifully transformed space",
    404: "We're sorry, but the page you're looking for seems to have vanished into thin air. Don't worry; you can explore our other interior design and architectural services by visiting our homepage or contacting us for assistance."
}