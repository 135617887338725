import { Helmet } from 'react-helmet';
import { META_DESCRIPTION, META_TITLE } from '../../constants/seo.constants';

interface MetaProps {
    url?: string;
    description?: string;
    title?: string;
    mediaUrl?: string;
    pathName?: string;
    keywords?: string;
}

const Meta = (props: MetaProps) => {
    const {
        title = META_TITLE.HOME,
        description = META_DESCRIPTION.HOME,
        mediaUrl = '/images/logo.svg',
        url,
        keywords
    } = props;

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="date" content="2019-10-31" />
            <meta itemProp="datePublished" content="2019-10-31" />
            <meta name="keywords" content={keywords} />

            <meta itemProp="name" content="Livera" />
            <meta itemProp="url" content={url ?? 'https://livera.design'} />
            <meta itemProp="description" content={description} />
            <meta itemProp="thumbnailUrl" content={mediaUrl} />
            <link rel="image_src" href={mediaUrl} />
            <meta itemProp="image" content={mediaUrl} />
            <link rel="canonical" href={url} />

            {/* <!-- Open Graph / Facebook --> */}
            <meta property="og:type" content="website" />
            <meta property="og:url" content={url ?? 'https://livera.design'} />
            <meta property="og:site_name" content="Livera" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={mediaUrl} />
            <meta property="og:image:alt" content="Livera Image" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
        </Helmet>
    );
}

export default Meta